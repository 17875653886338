<template>
  <div>
    <v-row no-gutters class="register_panel" :class="$vuetify.breakpoint.mdAndDown ? '' : 'my-12'">
      <v-row no-gutters class="register_wrapper" :class="$vuetify.breakpoint.mdAndDown ? 'px-5 py-5' : 'px-12 py-8'">
        <v-col class="no-gutters">
          <v-row no-gutters class="sign-up-title cols" v-if="$vuetify.breakpoint.mdAndUp">
            <label class="h6">{{ $t(`label.signUp`) }}</label>
          </v-row>
          <v-window v-model="step" :touchless="true">
            <v-window-item :value="0" :key="0">
              <v-form ref="registerForm1">
                <v-card-text class="ma-0 pa-0">
                  <app-form-field
                    :enabledLabel="true"
                    v-model.trim="registerObj.memberCode"
                    :label="$t(`field.username`)"
                    key=""
                    :errorMsg="userNameErrorMsg"
                    :blur="checkUsername"
                    :rules="validator.usernameRules()"
                  ></app-form-field>
                  <app-form-field
                    :enabledLabel="true"
                    v-model.trim="registerObj.password"
                    :label="$t(`field.password`)"
                    :rules="validator.passwordRules()"
                    :allowShowPassword="false"
                    type="password"
                  ></app-form-field>
                  <app-form-field
                    :enabledLabel="true"
                    v-model.trim="confirmNewPassword"
                    :label="$t(`field.confirmPassword`)"
                    :rules="validator.confirmPasswordRules(registerObj.password, confirmNewPassword)"
                    :allowShowPassword="false"
                    type="password"
                  ></app-form-field>
                </v-card-text>
                <v-row no-gutters class="d-flex justify-center align-center cols">
                  <v-btn class="text-capitalize circle-button" @click="this.nextPage">
                    {{ $t(`button.next`) }}
                    <v-icon>arrow_forward</v-icon>
                  </v-btn>
                </v-row>
              </v-form>
            </v-window-item>
            <v-window-item :value="1" :key="1">
              <v-form ref="registerForm2">
                <captcha ref="captchaCode" v-model.trim="captcha" :isOptional="false" :errorMsg="captchaErrorMsg" :rules="validator.captchaRules()"></captcha>
                <v-row no-gutters>
                  <v-col cols="12">
                    <app-form-field
                      :enabledLabel="true"
                      v-model.trim="registerObj.currency"
                      :label="$t(`field.currency`)"
                      type="select"
                      :disabled="true"
                      :rules="validator.dropDownListRules()"
                      :items="currencyList"
                      class="black--text"
                    ></app-form-field>
                  </v-col>
                </v-row>
                <v-card-text class="ma-0 pa-0">
                  <label class="input-field-label ma-0 pb-2 text-capitalize d-block">
                    {{ $t(`field.phoneNumber`) }}
                    <span class="red--text ml-1">*</span>
                  </label>
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-select
                        elevation="2"
                        v-model.trim="registerObj.dialCode"
                        class="input-field mr-2 black--text"
                        item-text="text"
                        item-value="value"
                        :items="mobileNumberPrefixList"
                        dense
                        outlined
                        :readonly="true"
                      ></v-select>
                    </v-col>
                    <v-col cols="7">
                      <app-form-field :enabledLabel="false" v-model.trim="registerObj.mobileNumber" :rules="validator.mobileNumberRules(registerObj.language)" :isOptional="false"></app-form-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
              <v-form ref="registerForm3">
                <v-card-text class="ma-0 pa-0">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <app-form-field
                        :enabledLabel="true"
                        v-model.trim="registerObj.email"
                        :label="$t(`field.email`)"
                        :isOptional="true"
                        :placeholder="$t(`fieldHint.fillUpHere`)"
                        :rules="validator.emailRules()"
                      ></app-form-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <app-form-field
                        :enabledLabel="true"
                        v-model.trim="registerObj.referralCode"
                        :label="$t(`field.referralCode`)"
                        :rules="validator.referralCodeRules()"
                        :readonly="isReferralCodeReadonly"
                        :isOptional="true"
                      ></app-form-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-checkbox class="mt-0 pt-0" :rules="validator.requiredRules()">
                        <template v-slot:label>
                          <p class="caption pt-2 mb-0">
                            <label>{{ $t(`message.registerDisclaimer_1`) }}</label>
                            <label class="secondary&#45;&#45;text font-weight-bold" style="cursor: pointer" @click="openRegisterTncDialog">{{ $t(`message.registerDisclaimer_2`) }}</label>
                          </p>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="d-flex justify-center align-center">
                    <v-btn class="circle-button text-capitalize" @click="this.previousPage">
                      <v-icon>arrow_back</v-icon>
                      {{ $t(`button.back`) }}
                    </v-btn>
                    <v-btn class="circle-button text-capitalize ml-2" style="background-color: #142590" @click="this.register">
                      {{ $t(`button.complete`) }}
                      <v-icon>check</v-icon>
                    </v-btn>
                  </v-row>
                </v-card-text>
              </v-form>
            </v-window-item>
            <!-- <v-window-item :value="2">
                      <div class="pa-4 text-center">
                          <v-img class="mb-4" contain height="128" src="https://cdn.vuetifyjs.com/images/logos/v.svg"></v-img>
                          <h3 class="text-h6 font-weight-light mb-2">
                              Welcome to Vuetify
                          </h3>
                          <span class="text-caption grey--text">Thanks for signing up!</span>
                      </div>
                  </v-window-item> -->
            <!-- <v-row no-gutters>
                      <v-col cols=12 class="mt-5">
                      <v-item-group v-model="step" class="text-center" mandatory>
                          <v-item v-for="n in regSteps" :key="`btn-${n}`" v-slot="{ active, toggle }">
                          <v-btn color="white" class="ma-2 resgiter-dialog-pagination"
                                  active-class="resgiter-dialog-pagination-active" x-small icon :input-value="active"
                                  @click="toggle" :disabled="true">
                              {{ n }}
                          </v-btn>
                          </v-item>
                          <v-item v-slot="{ active, toggle }">
                          <v-btn active-class="resgiter-dialog-pagination-active" icon :input-value="active" :disabled="true">
                              <v-icon>check_circle</v-icon>
                          </v-btn>
                          </v-item>
                      </v-item-group>
                      </v-col>
                  </v-row> -->
          </v-window>
          <!-- <p class="mt-5 text-center body-1">
                          {{ $t(`message.alreadyHaveAccount`) }}
                          <v-btn text height="auto" @click="openLogin" class="pa-0 text-uppercase font-weight-bold secondary--text">{{ $t(`label.signIn`) }}
                          </v-btn>
                      </p> -->
          <!-- <div v-if="step == 0">
                      <v-row no-gutters class="py-6">
                          <v-col cols="12">
                          <v-divider class="dialog-divider"></v-divider>
                          </v-col>
                      </v-row>
                      <v-row no-gutters>
                          <v-col cols=12>
                          <label class="input-field-label ma-0 pb-1 d-block title_color2--text">
                              {{ $t(`message.alreadyHaveAccount`) }}</label>
                          </v-col>
                      </v-row>
                      <v-row no-gutters>
                          <v-col cols="12">
                          <v-btn height="auto" @click="openLogin" color="black" depressed
                                  class="primary-button theme-button text-capitalize pa-3 font-weight-bold yellow--text subtitle-1">
                              {{ $t(`button.login`) }}
                          </v-btn>
                          </v-col>
                      </v-row>
                  </div> -->
        </v-col>
        <v-col class="no-gutters" cols="12" :md="8">
          <v-carousel delimiter-icon="mdi-minus" hide-delimiters :show-arrows="false" cycle interval="10000" height="auto">
            <v-carousel-item v-for="(registrationBanner, i) in registrationBannerContent" :key="i" style="display: grid">
              <v-img :src="`${registrationBanner.desktop_registration_banner}`" :contain="true"></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-row>
    <v-snackbar class="pa-0" style="z-index:1000 !important;top:7%" light timeout="-1" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'check_circle' : 'cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
  </div>
</template>

<script>
import stringFormat from 'string-format'
import { formValidator, uiHelper, locale, affDomainHelper } from '@/util'
import {
  MEMBER_CHECK_USERNAME,
  MEMBER_RESET_CHECK_USERNAME,
  MEMBER_REGISTER,
  MEMBER_SMS_REQUEST,
  MEMBER_SMS_VERIFY,
  MEMBER_GET_SMS_REQUIREMENT,
  MEMBER_RESET_SMS_VERIFICATION
} from '@/store/member.module'
import { SESSION, SHARED } from '@/constants/constants'
import { errorCodeHelper } from '@/util/error-code-helper'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { CMS_REGISTRATION_BANNER_ACTIVE } from '@/store/cms.module'
import Captcha from '@/components/Captcha.vue'

export default {
  name: 'appRegister',
  components: { Captcha },
  metaInfo: {
    link: [{ rel: 'canonical', href: window.location.origin + '/register/' }]
  },
  mounted() {
    console.log('/register mounted')
    this.$refs.registerForm1.reset()
    this.checkAffiliateReferralCode()
    this.checkPhoneVerificationRequirements()
    this.generateRegisterMeta()
    this.generateRegisterPixel()
  },
  props: {
    openLoginDialog: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  created() {
    console.log('/register created')
    this.checkReferCode()
    this.metaTag()
    this.getRegistrationBanner()
  },
  data: () => ({
    currentLanguage: uiHelper.getLanguage(),
    defaultLanguage: SHARED.DEFAULT_LANGUAGE,
    registerTncUrl: '',
    registerTncDialog: {
      show: false,
      title: locale.getMessage(`label.registerAccountAgreement`)
    },
    validator: formValidator,
    stringFormat: stringFormat,
    isReferralCodeReadonly: false,
    confirmNewPassword: '',
    mobileNumberPrefixList: [
      {
        text: '+91',
        value: '91',
        currency: 'INR',
        lang: 'id'
      },
      {
        text: '+880',
        value: '880',
        currency: 'BDT',
        lang: 'bd'
      }
    ],
    currencyList: ['INR', 'BDT'],
    registerObj: {
      memberCode: '',
      password: '',
      currency: SHARED.DEFAULT_CURRENCY,
      email: '',
      dialCode: SHARED.DEFAULT_CURRENCY == 'BDT' ? '880' : '91',
      mobileNumber: '',
      verificationCode: '',
      line: '',
      referralCode: '',
      isEarlyBird: '0',
      domain: '',
      language: SHARED.DEFAULT_LANGUAGE,
      platform: '',
      fp: '',
      agent_team: '',
      utm_source: uiHelper.getCookie(SESSION.UTM_SOURCE),
      utm_medium: uiHelper.getCookie(SESSION.UTM_MEDIUM),
      utm_campaign: uiHelper.getCookie(SESSION.UTM_CAMPAIGN),
      s2: uiHelper.getCookie(SESSION.UTM_S2),
      cid: sessionStorage.getItem(SESSION.CID)
    },
    confirmedPassword: '',
    step: 0,
    regSteps: 2,
    banners: ['/static/image/register/'],
    errorMsg: undefined,
    userNameErrorMsg: undefined,
    alertShow: false,
    alertSuccess: false,
    alertMessage: '',
    captcha: '',
    captchaErrorMsg: undefined
  }),
  computed: {
    smsRequired() {
      return this.$store.state.member.smsVerificationRequired.required
    },
    smsVerificationSent() {
      return this.$store.state.member.smsVerificationSent.success
    },
    smsVerificationResult() {
      return this.$store.state.member.smsVerificationResult.success
    },
    smsVerificationError() {
      return this.$store.state.member.smsVerificationResult.message
    },
    smsRequestError() {
      return this.$store.state.member.smsVerificationSent.message
    },
    smsRequestAvailable() {
      return this.$store.state.member.smsVerificationSent.countDownTimer <= 0
    },
    smsCountdownTimer() {
      return this.$store.state.member.smsVerificationSent.countDownTimer.toString()
    },
    checkUsernameResult() {
      return this.$store.state.member.checkUsernameResult.completed
    },
    watchCurrency() {
      return this.registerObj.currency
    },
    registrationBannerContent() {
      return this.$store.state.cms.registrationBannerContent
    }
    // registerResult() {
    //   //console.log('registerResult', this.$store.state.member.registerResponse.complete)
    //   return this.$store.state.member.registerResponse.complete
    // }
  },
  watch: {
    // registerResult() {
    //   debugger;
    //   if(this.$store.state.member.registerResponse.complete){
    //     alert(this.$store.state.member.registerResponse.message);
    //     this.$store.dispatch(`${MEMBER_RESET_REGISTER_STORE}`)
    //   }
    //   console.log('this.$store.state.member.registerResponse', this.$store.state.member.registerResponse)
    // },
    watchCurrency() {
      const selected = this.mobileNumberPrefixList.find(x => x.currency == this.registerObj.currency)
      if (selected) {
        this.registerObj.dialCode = selected.value
      }
    },
    smsRequestError() {
      if (this.$store.state.member.smsVerificationSent.complete && !this.$store.state.member.smsVerificationSent.success) {
        this.errorDialog(this.$store.state.member.smsVerificationSent.code)
      }
    },
    checkUsernameResult() {
      if (this.$store.state.member.checkUsernameResult.completed) {
        if (this.$store.state.member.checkUsernameResult.success) {
          this.userNameErrorMsg = undefined
        } else {
          if (this.$store.state.member.checkUsernameResult.code == -2) {
            this.userNameErrorMsg = locale.getMessage(`fieldErrorMessage.usernameExceedLength`)
          } else if (this.$store.state.member.checkUsernameResult.code == -1) {
            this.userNameErrorMsg = locale.getMessage(`fieldErrorMessage.usernameUsed`)
          }
        }
        this.$store.dispatch(`${MEMBER_RESET_CHECK_USERNAME}`)
      }
    }
  },
  methods: {
    checkReferCode() {
      let af = this.$route.query.af
      let r = this.$route.query.refer_code
      let u_s = this.$route.query.utm_source
      let u_m = this.$route.query.utm_medium
      let u_c = this.$route.query.utm_campaign
      let s2 = this.$route.query.s2
      let hostName = uiHelper.getHost().toLowerCase()

      if (typeof u_s != 'undefined' && u_s != '' && u_s != null) {
        uiHelper.setCookie(SESSION.UTM_SOURCE, u_s, 1)
      }
      if (typeof u_m != 'undefined' && u_m != '' && u_m != null) {
        uiHelper.setCookie(SESSION.UTM_MEDIUM, u_m, 1)
      }
      if (typeof u_c != 'undefined' && u_c != '' && u_c != null) {
        uiHelper.setCookie(SESSION.UTM_CAMPAIGN, u_c, 1)
      }
      if (typeof s2 != 'undefined' && s2 != '' && s2 != null) {
        uiHelper.setCookie(SESSION.UTM_S2, s2, 1)
      }
      let agent_code = affDomainHelper.getAffCodeBasedOnDomain(hostName, af)
      let doneFirstRedirectReg = uiHelper.getCookie(SESSION.DONE_FIRST_REDIRECT_REGISTRATION)

      if (typeof agent_code != 'undefined' && agent_code !== '' && agent_code != null) {
        uiHelper.setCookie(SESSION.AFF_CODE, agent_code, 365)
      } else {
        if (typeof r != 'undefined' && r != '' && r != null) {
          uiHelper.setCookie(SESSION.REFER_CODE, r, 365)
        }
      }
    },
    generateRegisterMeta() {
      const metaTag = document.createElement('meta')
      metaTag.setAttribute('http-equiv', 'delegate-ch')
      metaTag.setAttribute(
        'content',
        'sec-ch-ua https://track.jayaup.com; sec-ch-ua-mobile https://track.jayaup.com; sec-ch-ua-arch https://track.jayaup.com; sec-ch-ua-model https://track.jayaup.com; sec-ch-ua-platform https://track.jayaup.com; sec-ch-ua-platform-version https://track.jayaup.com; sec-ch-ua-bitness https://track.jayaup.com; sec-ch-ua-full-version-list https://track.jayaup.com; sec-ch-ua-full-version https://track.jayaup.com'
      )

      document.head.appendChild(metaTag)
    },
    generateRegisterPixel() {
      var scripts = ['/static/js/jayaRegisterPixel.js']
      scripts.forEach(script => {
        let tag = document.createElement('script')
        tag.setAttribute('src', script)
        document.head.appendChild(tag)
      })
    },
    metaTag() {
      uiHelper.setMetaTag('url', window.location.origin + '/register/')
      uiHelper.setTitle(`${locale.getMessage('meta.register_title')}`)
      uiHelper.setMetaTag('title', `${locale.getMessage('meta.register_title')}`)
      uiHelper.setMetaTag('description', `${locale.getMessage('meta.register_description')}`)
      uiHelper.setMetaTag('keywords', `${locale.getMessage('meta.register_keyword')}`)
      uiHelper.setCanonical('https://www.jaya9.app/register/')
    },
    checkUsername() {
      if (this.registerObj.memberCode.trim() !== '') {
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_CHECK_USERNAME}`, {
          registerObj
        })
      }
    },
    errorDialog(errorCode) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.register`)
      dialog.dialogXButton = null
      dialog.message.push(errorCodeHelper.getErrorCodeDesc(errorCode))
      dialog.button.push({
        title: locale.getMessage(`label.close`),
        action: () => this.closePageDialog()
      })
      this.openPageDialog(dialog)
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      this.$eventHub.$emit('close-page-dialog', true)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    checkPhoneVerificationRequirements() {
      this.$store.dispatch(`${MEMBER_GET_SMS_REQUIREMENT}`)
    },
    openRegisterTncDialog() {
      this.registerTncUrl = `${process.env.BASE_URL}static/html/other/${uiHelper.getLanguage()}/register-tnc.html`
      this.registerTncDialog.show = true
      this.registerTncDialog.dialogXButton = this.closePromotionTncDialog
    },
    closeRegisterTncDialog() {
      this.registerTncDialog.show = false
    },
    openLogin() {
      // this.$refs.registerForm1.reset()
      // this.$refs.registerForm2.reset()
      this.openLoginDialog()
    },

    register() {
      // if (this.smsRequired && !this.smsVerificationSent && !this.smsVerificationResult.success) {
      //   this.requestSmsVerificationCode()
      //   return
      // }
      if (this.captcha != null && this.$refs.registerForm2.validate() && this.$refs.registerForm3.validate()) {
        let jObj = this
        this.registerObj.platform = uiHelper.getPlatform()
        this.registerObj.domain = uiHelper.getHostname()
        this.registerObj.language = uiHelper.getLanguage()
        let existFp = typeof uiHelper.getCookie('fp_visitor') != 'undefined' && uiHelper.getCookie('fp_visitor') != null && uiHelper.getCookie('fp_visitor') != ''
        if (existFp) {
          jObj.registerObj.fp = uiHelper.getCookie('fp_visitor')
          let registerObj = {
            ...this.registerObj,
            captchaId: this.$refs.captchaCode.$refs.code.id,
            captchaCode: this.captcha
          }
          this.$store.dispatch(`${MEMBER_REGISTER}`, {
            registerObj
          })
        } else {
          try {
            jObj.registerObj.fp = uiHelper.getFingerprints()
            if (jObj.registerObj.fp != '' && jObj.registerObj.fp != null) {
              uiHelper.setCookie('fp_visitor', jObj.registerObj.fp, 30)
              let registerObj = {
                ...jObj.registerObj,
                captchaId: this.$refs.captchaCode.$refs.code.id,
                captchaCode: this.captcha
              }
              this.$store.dispatch(`${MEMBER_REGISTER}`, {
                registerObj
              })
            }
          } catch (err) {
            log(err)
            let registerObj = {
              ...this.registerObj,
              captchaId: this.$refs.captchaCode.$refs.code.id,
              captchaCode: this.captcha
            }
            this.$store.dispatch(`${MEMBER_REGISTER}`, {
              registerObj
            })
          }
          //this.registerObj.currency = uiHelper.getCurrency()
        }
      }
    },
    formatSeconds(secs) {
      const pad = n => (n < 10 ? `0${n}` : n)

      const h = Math.floor(secs / 3600)
      const m = Math.floor(secs / 60) - h * 60
      const s = Math.floor(secs - h * 3600 - m * 60)

      return `${pad(m)}:${pad(s)}`
    },
    requestSmsVerificationCode() {
      this.$store.state.member.smsVerificationSent.success = false
      if (this.$refs.registerForm2.validate()) {
        this.registerObj.domain = uiHelper.getHostname()
        this.registerObj.language = uiHelper.getLanguage()
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_SMS_REQUEST}`, {
          registerObj
        })
      }
    },
    async verifySmsCode() {
      if (this.$refs.registerForm.validate()) {
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_SMS_VERIFY}`, {
          registerObj
        })
      }
    },
    checkAffiliateReferralCode() {
      let referCode = uiHelper.getCookie(SESSION.REFER_CODE)
      let afCode = uiHelper.getCookie(SESSION.AFF_CODE)
      let cid = uiHelper.getCidFromUrl()

      if (afCode != null && afCode != '') {
        this.registerObj.agent_team = afCode
        this.isReferralCodeReadonly = true
      } else if (referCode != null && referCode != '') {
        this.registerObj.referralCode = referCode
        this.isReferralCodeReadonly = true
      } else {
        this.registerObj.referralCode = ''
        this.isReferralCodeReadonly = false
      }

      if (cid) {
        this.registerObj.cid = cid
      }
    },
    nextPage() {
      if (this.$refs.registerForm1.validate() && !this.userNameErrorMsg) {
        this.step++
      }
    },
    previousPage() {
      this.step--
    },
    openAlert(success, msg) {
      this.alertSuccess = success
      this.alertMessage = msg
      this.alertShow = true
    },
    getRegistrationBanner() {
      let obj = {
        language: uiHelper.getLanguage()
      }
      this.$store.dispatch(`${CMS_REGISTRATION_BANNER_ACTIVE}`, {
        obj
      })
    }
  }
}
</script>

<style lang="scss">
.register_panel {
  .register_wrapper {
    max-width: 1240px;
    margin: auto;
    border: 8px solid var(--v-primary-base);
    display: flex;
    justify-content: space-between;
    gap: 1em;

    .sign-up-title {
      justify-content: center;
      color: var(--v-secondary-base);
      border-bottom: 1px solid var(--v-secondary-base);
      padding-bottom: 0.5em;
      margin-bottom: 1.5em;
    }

    .circle-button {
      padding: 12px;
      border-radius: 8px;
      background-color: var(--v-primary-lighten1);
      color: white;
    }

    .circle-button:hover {
      background-color: var(--v-primary-base);
    }

    .otp-button {
      background-color: var(--v-primary-base);
      border-radius: 4px;
    }

    .v-select__selection {
      color: #000000;
    }

    .input-field .v-icon,
    .input-field .v-input__icon {
      color: var(--v-primary-base);
    }

    .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {
      margin-top: 0px;
    }
  }
}

@media (max-width: 599px) {
  .register_panel {
    background-color: #ffffff;

    .register_wrapper {
      border: unset;
    }
  }
}
</style>
